import classes from "./Team.module.css";
import james from "../../Images/Bob-Betteridge-1566-1__FocusFillWzI5MiwzMDAsIngiLDk0XQ.jpg";
import Sean from "../../Images/Brian-Borich-2-copy__FocusFillWzI5MiwzMDAsInkiLDRd.jpg";
import max from "../../Images/Craig-Alcock-v2__FocusFillWzI5MiwzMDAsInkiLDRd.jpg";
import fenner from "../../Images/Harry-Campbell-copy__FocusFillWzI5MiwzMDAsIngiLDRd.jpg";
import john from "../../Images/John-Brussa-color-copy-v2__FocusFillWzI5MiwzMDAsIngiLDRd.jpg";
import ryan from "../../Images/Ted-Brown-1452__FocusFillWzI5MiwzMDAsIngiLDYxXQ.jpg";
import lexa from "../../Images/Leslie-Anne-Bowyer-524__FocusFillWzI5MiwzMDAsIngiLDQ3XQ.jpg";
import emily from "../../Images/Eno-Akpoguma-color-copy__FocusFillWzI5MiwzMDAsIngiLDRd.jpg";
import judy from "../../Images/Marlena-Bova-color-copy__FocusFillWzI5MiwzMDAsIngiLDRd.jpg";
import carly from "../../Images/Nicole-Broeke-copy__FocusFillWzI5MiwzMDAsIngiLDRd.jpg";

const Team = () => {
  return (
    <section className={classes.team}>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={james} alt="james bailey" />
        </div>
        <div className={classes.contact}>
          <h2>James Bailey</h2>
          <p>Partner</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={Sean} alt="Sean Creed" />
        </div>
        <div className={classes.contact}>
          <h2>Sean Creed</h2>
          <p>Partner</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={max} alt="Max mallin" />
        </div>
        <div className={classes.contact}>
          <h2>Max mallin</h2>
          <p>Associate</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={fenner} alt="Fenner Moeran" />
        </div>
        <div className={classes.contact}>
          <h2>Fenner Moeran</h2>
          <p>Associate</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={john} alt="John Wardell Cooper" />
        </div>
        <div className={classes.contact}>
          <h2>John Wardell Cooper</h2>
          <p>Partner</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={ryan} alt="Ryan Algar" />
        </div>
        <div className={classes.contact}>
          <h2>Bruce Davis</h2>
          <p>Partner</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={lexa} alt="Lexa Hilliard" />
        </div>
        <div className={classes.contact}>
          <h2>Lexa Hilliard</h2>
          <p>Associate</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={emily} alt="Emily Campbell" />
        </div>
        <div className={classes.contact}>
          <h2>Emily Campbell</h2>
          <p>Associate</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={judy} alt="Judy Grew" />
        </div>
        <div className={classes.contact}>
          <h2>Judy Grew</h2>
          <p>Partner</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={carly} alt="Carly Baker" />
        </div>
        <div className={classes.contact}>
          <h2>Carly Baker</h2>
          <p>Associate</p>
        </div>
      </div>
    </section>
  );
};

export default Team;
