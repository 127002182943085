import { Fragment } from "react";
import RootLayout from "./pages/RootLayout";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

import Body from "./components/home-body/Body";
import PracticeAreas from "./components/main/PracticeAreas";
import Industries from "./components/main/Industries";
import AboutUs from "./components/main/AboutUs";

import Team from "./components/main/Team";
import ErrorModal from "./components/UI/ErrorModal";
import ContactUs from "./components/contact/ContactUs";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorModal />}>
      <Route index element={<Body />} />
      <Route path="/practiceAreas" element={<PracticeAreas />} />
      <Route path="/industries" element={<Industries />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/team" element={<Team />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);

function App() {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
