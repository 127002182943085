import { Link } from "react-router-dom";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <section className={classes.footer}>
      <nav className={classes.nav}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Link to="/practiceAreas">Practice Areas</Link>
          </li>
          <li>
            <Link to="/industries">Industries</Link>
          </li>
          <li>
            <Link to="/contactUs">Contact Us</Link>
          </li>
          <li>
            <Link to="/aboutUs">About Us</Link>
          </li>
        </ul>
      </nav>
      <div className={classes.address}>
        <div className={classes.office}>
          <h2>Sean Wilberforce Chambers</h2>
          <address>
            8 New Square, <br />
            Lincoln's Inn, <br />
            London, WC2A 3QP
          </address>
          <address>(DX 311 Chancery Lane)</address>
        </div>
        <div className={classes.contact}>
          <p>Email → support@seanwilberforcechambers.com</p>
          <p>Tel → +44 (0)74 5120 6884</p>
        </div>
      </div>
      <p>
        SeanWilberforceChambers | @ Copyright SeanWilberforceChambers 2025. All
        rights reserved
      </p>
    </section>
  );
};

export default Footer;
